import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [hoverSection, setHoverSection] = useState<string | null>(null);
  const [overlayContent, setOverlayContent] = useState<string | null>(null);
  const [showRSVPForm, setShowRSVPForm] = useState(false);
  const cursorRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const touchPosRef = useRef({ x: 0, y: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSurfGame, setShowSurfGame] = useState(false);
  const [gameScore, setGameScore] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);
  const [highScores, setHighScores] = useState<number[]>([]);
  const surfingAudioRef = useRef<HTMLAudioElement>(null);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  const handleMouseEnter = (sectionName: string) => {
    if (!isTouchDevice()) {
      setHoverSection(sectionName);
      if (cursorRef.current) {
        cursorRef.current.classList.add('section-hover');
      }
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice()) {
      setHoverSection(null);
      if (cursorRef.current) {
        cursorRef.current.classList.remove('section-hover');
      }
    }
  };

  const handleSectionClick = (sectionName: string) => {
    if (sectionName === 'RSVP') {
      setShowRSVPForm(true);
    } else {
      const content = `
        <div class="dialog-content">
          <h2>${sectionName}</h2>
          <p>${getAdventureText(sectionName)}</p>
        </div>
      `;
      setOverlayContent(content);
      if (sectionName === 'Gift Registry') {
        setTimeout(initializeCamera, 100);
      } else if (sectionName === 'Sea') {
        setTimeout(() => {
          const startButton = document.getElementById('start-surf');
          if (startButton) {
            startButton.addEventListener('click', initializeSurfGame);
          }
        }, 100);
      }
    }
  };

  const handleRSVPSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Get form data
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get('name') as string;
    const email = formData.get('email') as string;
    const attending = formData.get('attending') as string;
    const plusOne = formData.get('plusOne') as string;
    const dietary = formData.get('dietary') as string;
    const message = formData.get('message') as string;

    try {
      const response = await fetch('https://api.shehata.app/wedding/guests', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          dietary_restrictions: dietary || "",
          plus_one: plusOne === "" ? "N/A" : plusOne,
          rsvp_status: attending || "",
          message: message || ""
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit RSVP');
      }

      setShowRSVPForm(false);
      alert('Thank you for your RSVP!');
      
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      alert('Sorry, there was an error submitting your RSVP. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAdventureText = (sectionName: string) => {
    switch (sectionName) {
      case 'Venue':
        return `
          <div class="venue-info">
            <div class="venue-section">
              <h3>Ceremony Information</h3>
              <p>Date: July 20th, 2025</p>
              <p>Start time: TBD</p>
              <p>Location: Quinta Nova do Hespanhol</p>
            </div>
            
            <div class="venue-section">
              <h3>Venue Info</h3>
              <p>Location: Quinta Nova do Hespanhol</p>
              <p>Address: Rua dos Perestrelos, 2565-136, Portugal</p>
            </div>

            <div class="venue-section">
              <h3>Updates</h3>
              <p>More details about the ceremony and venue will be sent to your email after RSVP.</p>
            </div>
          </div>
        `;
      case 'Gift Registry':
        return `
          <div class="registry-content">
            <div class="registry-message-container">
              <p class="registry-message">Well, well, well... look who's here! 👀 That gorgeous face is all we need at our wedding. Your presence is worth more than any toaster or fancy plates! ✨ (But if you insist on bringing something, your dance moves will do just fine! 💃🕺)</p>
            </div>
            <div class="camera-view">
              <video id="camera-feed" autoplay playsinline></video>
            </div>
          </div>
        `;
      case 'Hotel':
        return `
          <div class="hotel-info">
            <p class="hotel-intro">You are welcome to stay wherever you want, of course. However, if you want a discounted stay, we have promo codes from the 2 hotels in Lisbon and the hotel in Torres Vedras. After you start the booking process, when you enter the promo code, you may need to scroll down to find the price for "Events & Weddings".<br><br>
            If you want a hotel at the beach, Vila Gale in Ericeira was recommended.</p>
            
            <p class="hotel-intro">The venue is in the countryside, an hour outside of the Lisbon city center. So don't worry, if you decide to stay in the city, we'll provide shuttles to and from Lisbon. If you need help or have any questions, please contact Oz or Michelle. 🤠</p>
            
            <div class="hotel-map">
              <img src="/images/map_portugal.png" alt="Hotel locations map" />
            </div>

            <div class="hotel-cards">
              <a href="https://www.stayhotels.pt/torres-vedras-centro/en/" target="_blank" class="hotel-card">
                <div class="hotel-card-header">Stay Hotel Torres Vedras</div>
                <div class="hotel-card-details">
                  <div class="hotel-detail"><span>🕒</span> 20 minutes from venue</div>
                  <div class="hotel-detail"><span>🏨</span> Small town close to venue</div>
                  <div class="hotel-detail promo"><span>🎫</span>PROMO CODE: STAYPARTNERS</div>
                </div>
              </a>

              <a href="https://www.hoteldabaixa.com/en/" target="_blank" class="hotel-card">
                <div class="hotel-card-header">Hotel da Baixa</div>
                <div class="hotel-card-details">
                  <div class="hotel-detail"><span>🕒</span> 1 hour from venue</div>
                  <div class="hotel-detail"><span>🏨</span> Hotel in Lisbon</div>
                  <div class="hotel-detail promo"><span>🎫</span>PROMO CODE:  WEDDING25</div>
                </div>
              </a>

              <a href="https://www.blueliberdadehotel.com/" target="_blank" class="hotel-card">
                <div class="hotel-card-header">Blue Liberdade Hotel</div>
                <div class="hotel-card-details">
                  <div class="hotel-detail"><span>🕒</span> 1 hour from venue</div>
                  <div class="hotel-detail"><span>🏨</span> Hotel in Lisbon </div>
                  <div class="hotel-detail promo"><span>🎫</span>PROMO CODE: WEDDING25</div>
                </div>
              </a>

              <a href="https://www.vilagale.com/en/hotels/lisbon-coast/vila-gale-ericeira" target="_blank" class="hotel-card">
                <div class="hotel-card-header">Vila Gale Ericeira</div>
                <div class="hotel-card-note">(very large resort)</div>
                <div class="hotel-card-details">
                  <div class="hotel-detail"><span>🕒</span> 40 minutes from venue</div>
                  <div class="hotel-detail"><span>🏨</span> Beach Hotel in Ericeira </div>
                  <div class="hotel-detail promo"><span>🎫</span> -</div>
                </div>
              </a>
            </div>
          </div>
        `;
      case 'Sea':
        return `
          <div class="sea-content">
            <div class="sea-message">
              <p>Ready to catch some waves? 🏄‍♂️ Show us your surfing skills!</p>
            </div>
            <button id="start-surf" class="start-surf-btn">Start Surfing</button>
          </div>
        `;
      default:
        return "You've stumbled upon an unexplored area of the invitation. What secrets might it hold?";
    }
  };

  const initializeCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      const videoElement = document.getElementById('camera-feed') as HTMLVideoElement;
      if (videoElement) {
        videoElement.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const initializeSurfGame = () => {
    setShowSurfGame(true);
    setGameScore(0);
    setOverlayContent(null);
  };

  const handleTouchStart = (event: TouchEvent) => {
    if (cursorRef.current) {
      setIsDragging(true);
      const touch = event.touches[0];
      touchPosRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
      cursorRef.current.style.left = `${touch.clientX}px`;
      cursorRef.current.style.top = `${touch.clientY}px`;
    }
  };

  const checkTouchOverSection = (x: number, y: number) => {
    const sections = document.getElementsByClassName('hover-section');
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const rect = section.getBoundingClientRect();
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        if (cursorRef.current) {
          cursorRef.current.classList.add('section-hover');
        }
        if (section.classList.contains('venue-section')) {
          setHoverSection('Venue');
        } else if (section.classList.contains('rsvp-section')) {
          setHoverSection('RSVP');
        } else if (section.classList.contains('registry-section')) {
          setHoverSection('Gift Registry');
        } else if (section.classList.contains('hotel-section')) {
          setHoverSection('Hotel');
        } else if (section.classList.contains('sea-section')) {
          setHoverSection('Sea');
        }
        return true;
      }
    }
    if (cursorRef.current) {
      cursorRef.current.classList.remove('section-hover');
    }
    setHoverSection(null);
    return false;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (isDragging && cursorRef.current) {
      const touch = event.touches[0];
      touchPosRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
      cursorRef.current.style.left = `${touch.clientX}px`;
      cursorRef.current.style.top = `${touch.clientY}px`;
      
      checkTouchOverSection(touch.clientX, touch.clientY);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (cursorRef.current) {
      cursorRef.current.classList.remove('section-hover');
    }
    setHoverSection(null);
  };

  const toggleAudio = () => {
    if (showSurfGame) {
      if (surfingAudioRef.current) {
        if (isPlaying) {
          surfingAudioRef.current.pause();
        } else {
          surfingAudioRef.current.play();
        }
      }
    } else {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
      }
    }
    setIsPlaying(!isPlaying);
  };

  const startExperience = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
    setShowWelcome(false);
  };

  const getNameFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const encodedData = urlParams.get('r');
      
      if (!encodedData) return { name: 'Guest', msg: '' };

      // Decode base64 and parse JSON
      const decodedData = atob(encodedData);
      const jsonData = JSON.parse(decodedData);
      
      return {
        name: jsonData.name || 'Guest',
        msg: jsonData.msg || ''
      };
    } catch (error) {
      console.error('Error parsing invitation data:', error);
      return { name: 'Guest', msg: '' };
    }
  };

  const updateHighScores = (newScore: number) => {
    // Get existing scores from localStorage
    const scoresString = localStorage.getItem('surfHighScores');
    let scores: number[] = scoresString ? JSON.parse(scoresString) : [];
    
    // Add new score and sort in descending order
    scores.push(newScore);
    scores.sort((a, b) => b - a);
    
    // Keep only top 5 scores
    scores = scores.slice(0, 5);
    
    // Save back to localStorage
    localStorage.setItem('surfHighScores', JSON.stringify(scores));
    setHighScores(scores);
  };

  useEffect(() => {
    const scoresString = localStorage.getItem('surfHighScores');
    if (scoresString) {
      setHighScores(JSON.parse(scoresString));
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (cursorRef.current && !isTouchDevice()) {
        cursorRef.current.style.left = `${event.clientX}px`;
        cursorRef.current.style.top = `${event.clientY}px`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    
    if (isTouchDevice()) {
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (isTouchDevice()) {
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging]);

  useEffect(() => {
    if (!showSurfGame) return;

    let surferElement: HTMLElement | null = null;
    let waves: HTMLElement[] = [];
    let octopuses: HTMLElement[] = [];
    let sharks: HTMLElement[] = [];
    let lastSharkTime = 0;
    let surferY = 50;
    let velocity = 0;
    let lastWaveTime = 0;
    let lastOctopusTime = 0;
    let gameStartTime = Date.now();
    const initialWaveInterval = 1800;
    const initialOctopusInterval = 5000;
    const octopusStartDelay = 15000;
    const initialWaveSpeed = 0.8;
    const initialOctopusSpeed = 1;
    const momentumFactor = 0.85;
    const moveSpeed = 3.5;
    const initialSharkInterval = 4000;
    const sharkStartDelay = 25000;

    // Function to calculate current speed based on time elapsed
    const getCurrentSpeed = () => {
      const timeElapsed = (Date.now() - gameStartTime) / 1000; // time in seconds
      return initialWaveSpeed * (1 + timeElapsed / 15); // Increase speed by ~6.7% every second
    };

    // Function to calculate current wave interval based on time elapsed
    const getCurrentInterval = () => {
      const timeElapsed = (Date.now() - gameStartTime) / 1000;
      return Math.max(800, initialWaveInterval * (1 - timeElapsed / 30)); // Decrease interval, but not below 800ms
    };

    // Add new function for octopus interval
    const getOctopusInterval = () => {
      const timeElapsed = (Date.now() - gameStartTime) / 1000;
      return Math.max(3000, initialOctopusInterval * (1 - timeElapsed / 60));
    };

    // Add shark interval function
    const getSharkInterval = () => {
      const timeElapsed = (Date.now() - gameStartTime) / 1000;
      return Math.max(2500, initialSharkInterval * (1 - timeElapsed / 70));
    };

    const createNewWave = () => {
      const waveElement = document.createElement('div');
      waveElement.className = 'wave';
      waveElement.textContent = '🌊';
      const waveY = Math.random() * 90 + 5;
      waveElement.style.top = `${waveY}%`;
      waveElement.style.right = '-50px';
      document.querySelector('.surf-game-container')?.appendChild(waveElement);
      waves.push(waveElement);
    };

    const createNewOctopus = () => {
      const octopusElement = document.createElement('div');
      octopusElement.className = 'octopus';
      octopusElement.textContent = '🐙';
      
      // Random starting position (top or bottom)
      const startFromTop = Math.random() > 0.5;
      const startY = startFromTop ? 10 : 90;
      const targetY = startFromTop ? 
        Math.random() * 40 + 30 : // Target between 30% and 70% when starting from top
        Math.random() * 40 + 30;  // Target between 30% and 70% when starting from bottom
      
      octopusElement.style.top = `${startY}%`;
      octopusElement.style.right = '-50px';
      
      octopusElement.dataset.targetY = targetY.toString();
      octopusElement.dataset.startY = startY.toString();
      
      document.querySelector('.surf-game-container')?.appendChild(octopusElement);
      octopuses.push(octopusElement);
    };

    // Add createNewShark function
    const createNewShark = () => {
      const sharkElement = document.createElement('div');
      sharkElement.className = 'shark';
      sharkElement.textContent = '🦈';
      
      // Random starting position (top or bottom)
      const startFromTop = Math.random() > 0.5;
      const startY = startFromTop ? -10 : 110;
      const targetY = startFromTop ? Math.random() * 90 + 10 : Math.random() * 90 - 10;
      
      sharkElement.style.top = `${startY}%`;
      sharkElement.style.right = '-50px';
      
      sharkElement.dataset.targetY = targetY.toString();
      sharkElement.dataset.startY = startY.toString();
      
      document.querySelector('.surf-game-container')?.appendChild(sharkElement);
      sharks.push(sharkElement);
    };

    // Update the updateWaves function to include shark movement
    const updateWaves = (currentTime: number) => {
      // Create new wave
      if (currentTime - lastWaveTime > getCurrentInterval()) {
        createNewWave();
        lastWaveTime = currentTime;
      }

      // Create new octopus only after delay
      const timeElapsed = Date.now() - gameStartTime;
      if (timeElapsed > octopusStartDelay && currentTime - lastOctopusTime > getOctopusInterval()) {
        createNewOctopus();
        lastOctopusTime = currentTime;
      }

      // Create new shark
      if (timeElapsed > sharkStartDelay && currentTime - lastSharkTime > getSharkInterval()) {
        createNewShark();
        lastSharkTime = currentTime;
      }

      // Update waves
      const currentSpeed = getCurrentSpeed();
      waves = waves.filter(wave => {
        const right = parseFloat(wave.style.right || '-50');
        if (right >= 100) {
          wave.remove();
          return false;
        }
        wave.style.right = `${right + currentSpeed}%`;
        return true;
      });

      // Update octopuses with diagonal movement
      octopuses = octopuses.filter(octopus => {
        const right = parseFloat(octopus.style.right || '-50');
        const currentY = parseFloat(octopus.style.top);
        const targetY = parseFloat(octopus.dataset.targetY || '50');
        const startY = parseFloat(octopus.dataset.startY || '0');
        
        if (right >= 100) {
          octopus.remove();
          return false;
        }

        // Calculate progress of horizontal movement (0 to 1)
        const progress = right / 100;
        
        // Use linear interpolation for smooth Y movement
        const newY = startY + (targetY - startY) * progress;
        
        octopus.style.right = `${right + currentSpeed}%`;
        octopus.style.top = `${newY}%`;
        return true;
      });

      // Update sharks with diagonal movement
      sharks = sharks.filter(shark => {
        const right = parseFloat(shark.style.right || '-50');
        const currentY = parseFloat(shark.style.top);
        const targetY = parseFloat(shark.dataset.targetY || '50');
        const startY = parseFloat(shark.dataset.startY || '0');
        
        if (right >= 100) {
          shark.remove();
          return false;
        }

        const progress = right / 100;
        const newY = startY + (targetY - startY) * progress;
        
        shark.style.right = `${right + (currentSpeed * 1.2)}%`; // Sharks move slightly faster
        shark.style.top = `${newY}%`;
        return true;
      });
    };

    const jump = () => {
      velocity = -moveSpeed; // Move up
    };

    const dive = () => {
      velocity = moveSpeed; // Move down
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Space' || e.code === 'ArrowUp') {
        e.preventDefault();
        jump();
      } else if (e.code === 'ArrowDown') {
        e.preventDefault();
        dive();
      }
    };

    const handleTouch = (e: TouchEvent) => {
      e.preventDefault();
      const touch = e.touches[0];
      const gameContainer = document.querySelector('.surf-game-container');
      if (gameContainer) {
        const containerRect = gameContainer.getBoundingClientRect();
        const touchY = touch.clientY - containerRect.top;
        const containerMiddle = containerRect.height / 2;
        
        // Jump if touch is in upper half, dive if in lower half
        if (touchY < containerMiddle) {
          jump();
        } else {
          dive();
        }
      }
    };

    // Update collision detection to include sharks
    const checkCollisions = () => {
      if (!surferElement || showGameOver) return;

      const surferRect = surferElement.getBoundingClientRect();
      let isGameOver = false;

      // Check wave collisions
      for (const wave of waves) {
        const waveRect = wave.getBoundingClientRect();
        if (checkCollision(surferRect, waveRect)) {
          isGameOver = true;
          break;
        }
      }

      // Check octopus collisions if not already game over
      if (!isGameOver) {
        for (const octopus of octopuses) {
          const octopusRect = octopus.getBoundingClientRect();
          if (checkCollision(surferRect, octopusRect)) {
            isGameOver = true;
            break;
          }
        }
      }

      // Check shark collisions if not already game over
      if (!isGameOver) {
        for (const shark of sharks) {
          const sharkRect = shark.getBoundingClientRect();
          if (checkCollision(surferRect, sharkRect)) {
            isGameOver = true;
            break;
          }
        }
      }

      // Handle game over immediately if collision detected
      if (isGameOver) {
        // Stop all game updates first
        cancelAnimationFrame(animationFrame);
        
        // Clear all game objects
        waves.forEach(wave => wave.remove());
        octopuses.forEach(octopus => octopus.remove());
        sharks.forEach(shark => shark.remove());
        waves = [];
        octopuses = [];
        sharks = [];
        
        // Set game over state last
        setShowGameOver(true);
        return;
      }

      // Only score points if game is still active
      if (!isGameOver && !showGameOver) {
        let scoreIncrement = 0;

        // Waves count as 1 point
        waves.forEach(wave => {
          if (!wave.dataset.scored && wave.getBoundingClientRect().right < surferRect.left) {
            wave.dataset.scored = 'true';
            scoreIncrement += 1; // Explicitly set to 1 point for waves
          }
        });

        // Octopuses count as 5 points
        octopuses.forEach(octopus => {
          if (!octopus.dataset.scored && octopus.getBoundingClientRect().right < surferRect.left) {
            octopus.dataset.scored = 'true';
            scoreIncrement += 5;
          }
        });

        // Sharks count as 10 points
        sharks.forEach(shark => {
          if (!shark.dataset.scored && shark.getBoundingClientRect().right < surferRect.left) {
            shark.dataset.scored = 'true';
            scoreIncrement += 10;
          }
        });

        // Update score if there's any increment
        if (scoreIncrement > 0) {
          setGameScore(prev => prev + scoreIncrement);
        }
      }
    };

    const checkCollision = (rect1: DOMRect, rect2: DOMRect) => {
      // Calculate overlap area
      const xOverlap = Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left);
      const yOverlap = Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top);
      
      // Only count as collision if overlap is significant
      if (xOverlap <= 0 || yOverlap <= 0) return false;
      
      // Calculate overlap percentage relative to surfer size
      const surferArea = rect1.width * rect1.height;
      const overlapArea = xOverlap * yOverlap;
      const overlapPercentage = (overlapArea / surferArea) * 100;
      
      // Return true only if overlap is more than 30% of surfer size
      return overlapPercentage > 30;
    };

    const updateGame = (timestamp: number) => {
      if (!surferElement) return;

      // Apply momentum
      velocity *= momentumFactor;
      surferY += velocity;

      // Keep surfer within bounds
      if (surferY < 5) {
        surferY = 5;
        velocity = 0;
      }
      if (surferY > 95) {
        surferY = 95;
        velocity = 0;
      }

      surferElement.style.top = `${surferY}%`;

      updateWaves(timestamp);
      checkCollisions();
    };

    // Define initializeGame before using it
    const initializeGame = () => {
      surferElement = document.querySelector('.surfer');
      if (surferElement) {
        surferElement.style.top = `${surferY}%`;
      }
      createNewWave(); // Create first wave
    };

    initializeGame();

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('touchstart', handleTouch);
    
    // Use requestAnimationFrame for smoother animation
    let animationFrame: number;
    const gameLoop = (timestamp: number) => {
      if (!showGameOver) {
        updateGame(timestamp);
        animationFrame = requestAnimationFrame(gameLoop);
      }
    };
    animationFrame = requestAnimationFrame(gameLoop);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('touchstart', handleTouch);
      cancelAnimationFrame(animationFrame);
      // Clean up both waves, octopuses, and sharks
      waves.forEach(wave => wave.remove());
      octopuses.forEach(octopus => octopus.remove());
      sharks.forEach(shark => shark.remove());
    };
  }, [showSurfGame]);

  useEffect(() => {
    if (showSurfGame) {
      // Pause regular background music
      if (audioRef.current) {
        audioRef.current.pause();
      }
      // Start surfing music
      if (surfingAudioRef.current) {
        surfingAudioRef.current.play();
        surfingAudioRef.current.volume = 0.7; // Slightly lower volume for game music
      }
    } else {
      // Stop surfing music
      if (surfingAudioRef.current) {
        surfingAudioRef.current.pause();
        surfingAudioRef.current.currentTime = 0;
      }
      // Resume regular background music if it was playing
      if (audioRef.current && isPlaying) {
        audioRef.current.play();
      }
    }
  }, [showSurfGame, isPlaying]);

  return (
    <>
      {!isMobileDevice() ? (
        <div className="desktop-message">
          <div className="desktop-content">
            <h1>Please Open on Mobile. We detected a desktop device that has not enough computing power to run this site.</h1>
            <p>Michelle & Oz's wedding invitation is designed for mobile devices only. We apologize not being inclusive enough for x86 processors.</p>
          </div>
        </div>
      ) : (
        <div className="wedding-invitation">
          {showWelcome && (
            <div className="welcome-overlay">
              <div className="welcome-content">
                <h3>Welcome {getNameFromUrl().name}!</h3>
                {getNameFromUrl().msg && (
                  <p className="welcome-message">{getNameFromUrl().msg}</p>
                )}
                <h6>Drag your finger across the screen to explore the invitation.</h6>
                <p className="welcome-hint">Interactive sections will light up as you drag over them.</p>
                <button onClick={startExperience} className="start-button">
                  Start
                </button>
              </div>
            </div>
          )}
          <audio ref={audioRef} loop>
            <source src="/audio/background.mp3" type="audio/mpeg" />
          </audio>
          <audio ref={surfingAudioRef} loop>
            <source src="/audio/surfing_music.mp3" type="audio/mpeg" />
          </audio>
          <button 
            className="audio-control" 
            onClick={toggleAudio}
            aria-label={isPlaying ? 'Pause Music' : 'Play Music'}
          >
            {isPlaying ? '🔊' : '🔇'}
          </button>
          <img 
            src="/images/pred_3.png" 
            alt="8-bit Wedding Invitation" 
            className="invitation-image"
          />
          <div 
            className="hover-section venue-section visible"
            onMouseEnter={() => handleMouseEnter('Venue')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSectionClick('Venue')}
          ></div>
          <div 
            className="hover-section rsvp-section visible"
            onMouseEnter={() => handleMouseEnter('RSVP')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSectionClick('RSVP')}
          ></div>
          <div 
            className="hover-section registry-section visible"
            onMouseEnter={() => handleMouseEnter('Gift Registry')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSectionClick('Gift Registry')}
          ></div>
          <div 
            className="hover-section hotel-section visible"
            onMouseEnter={() => handleMouseEnter('Hotel')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSectionClick('Hotel')}
          ></div>
          <div 
            className="hover-section sea-section visible"
            onMouseEnter={() => handleMouseEnter('Sea')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSectionClick('Sea')}
          ></div>
          {hoverSection && (
            <div className="section-hint">{hoverSection}</div>
          )}
          <div 
            ref={cursorRef} 
            className={`custom-cursor ${isDragging ? 'dragging' : ''}`}
            style={{ display: isTouchDevice() ? 'block' : undefined }}
          ></div>
          {overlayContent && (
            <div className="overlay" onClick={() => setOverlayContent(null)}>
              <div className="overlay-content" onClick={e => e.stopPropagation()}>
                <button 
                  className="dialog-cancel" 
                  onClick={() => setOverlayContent(null)}
                  aria-label="Close dialog"
                >
                  ✕
                </button>
                <div dangerouslySetInnerHTML={{ __html: overlayContent }} />
              </div>
            </div>
          )}
          {showRSVPForm && (
            <div className="overlay" onClick={() => setShowRSVPForm(false)}>
              <div className="overlay-content rsvp-form" onClick={e => e.stopPropagation()}>
                <button 
                  className="dialog-cancel" 
                  onClick={() => setShowRSVPForm(false)}
                  aria-label="Close RSVP form"
                >
                  ✕
                </button>
                <h2>RSVP</h2>
                <form onSubmit={handleRSVPSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" name="name" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="attending">Will you be attending?</label>
                    <select id="attending" name="attending" required>
                      <option value="">Select...</option>
                      <option value="yes">Yes!</option>
                      <option value="no">No :(</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="plusOne">Who are you coming with?</label>
                    <input 
                      type="text" 
                      id="plusOne" 
                      name="plusOne" 
                      placeholder="Leave empty if coming alone"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dietary">Dietary restrictions?</label>
                    <textarea id="dietary" name="dietary" rows={2}></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message for the couple:</label>
                    <textarea id="message" name="message" rows={4}></textarea>
                  </div>
                  <div className="form-actions">
                    <button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <div className="spinner-container">
                          <div className="spinner"></div>
                          <span>Submitting...</span>
                        </div>
                      ) : (
                        'Submit RSVP'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {showSurfGame && (
            <div className="surf-game-overlay">
              <div className="surf-game-container">
                <div className="score">Score: {gameScore}</div>
                <div className="surfer" style={{ left: '5%' }}>🏄‍♂️</div>
                <div className="wave">🌊</div>
                <button 
                  className="close-game"
                  onClick={() => {
                    setShowSurfGame(false);
                    setShowGameOver(false);
                    setGameScore(0);
                  }}
                  aria-label="Close game"
                >
                  ✕
                </button>
                {showGameOver && (
                  <div className="game-over" onClick={(e) => e.stopPropagation()}>
                    <h2>Game Over!</h2>
                    <p>Final Score: {gameScore}</p>
                    <button 
                      className="play-again-btn"
                      onClick={() => {
                        setShowGameOver(false);
                        setGameScore(0);
                        setShowSurfGame(true);
                      }}
                    >
                      Play Again
                    </button>
                    <button 
                      className="close-game-btn"
                      onClick={() => {
                        setShowGameOver(false);
                        setShowSurfGame(false);
                        setGameScore(0);
                      }}
                    >
                      Exit Game
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default App;
